<!--
 * @Description: 协同单列表
 * @Author: ChenXueLin
 * @Date: 2021-08-17 09:18:43
 * @LastEditTime: 2022-07-15 16:19:50
 * @LastEditors: ChenXueLin
-->
<!--  -->
<template>
  <div class="content-view-wrapper pd-10">
    <div
      class="content-view section-wrapper"
      ref="sectionWrapper"
      :element-loading-background="loadingBackground"
      v-loading="loading"
    >
      <!-- 搜索头部 start -->
      <table-title @resize="handleTableTitleResize">
        <template v-slot:searchForm>
          <el-form class="search-list" ref="searchForm" :model="searchForm">
            <el-form-item class="search-item--1" prop="taskNumber">
              <el-input
                v-model="searchForm.taskNumber"
                placeholder="请输入协同单号"
                title="协同单号"
              ></el-input>
            </el-form-item>
            <el-form-item class="search-item--1" prop="title">
              <el-input
                v-model="searchForm.title"
                placeholder="请输入协同单标题"
                title="请输入协同单标题"
              ></el-input>
            </el-form-item>
            <el-form-item class="search-item--1" prop="taskStatus">
              <e6-vr-select
                v-model="searchForm.taskStatus"
                :data="workStatusList"
                placeholder="协同单状态"
                title="协同单状态"
                clearable
                :loading="selectLoading"
                :props="{
                  id: 'codeValue',
                  label: 'codeName'
                }"
              ></e6-vr-select>
            </el-form-item>
            <el-form-item class="search-item--1" prop="operaEmployeeId">
              <all-user-search
                v-model="searchForm.operaEmployeeId"
                clear
                placeholder="当前操作人"
                title="当前操作人"
                :propsConfig="{
                  id: 'userId',
                  label: 'employeeName'
                }"
              >
              </all-user-search>
            </el-form-item>
            <el-form-item class="search-item--1" prop="createByName">
              <el-input
                v-model="searchForm.createByName"
                placeholder="创建人"
                title="创建人"
              ></el-input>
            </el-form-item>
            <el-form-item class="search-item--2" prop="createTime">
              <e6-date-picker
                popper-class="no-atTheMoment"
                label="创建时间"
                ref="effectiveDatePicker"
                v-model="searchForm.createTime"
                title="创建时间"
                :picker-options="pickerOptions('searchForm.createTime')"
                :default-time="['00:00:00', '23:59:59']"
                start-placeholder="创建时间（始）"
                end-placeholder="创建时间（止）"
              ></e6-date-picker>
            </el-form-item>
            <el-form-item class="search-item--buttons">
              <el-button type="primary" @click="handleSearch">查询</el-button>
              <el-button class="reset" @click="handleReset">重置</el-button>
            </el-form-item>
          </el-form>
        </template>
        <template v-slot:operateAction>
          <i class="e6-icon-add_line" title="创建工单" @click="createTask"></i>
        </template>
      </table-title>
      <!-- 搜索头部 end -->

      <!-- 表格 start -->
      <section class="table-wrapper">
        <el-table
          border
          :height="resizeViewHeight + 'px'"
          :data="tableData"
          highlight-current-row
          ref="elTable"
        >
          <!-- <el-table-column type="selection" width="40"></el-table-column> -->
          <!-- 序号 start -->
          <el-table-column
            label="序号"
            width="50"
            fixed="left"
            header-align="center"
            align="center"
          >
            <template slot-scope="scope">
              <span>
                {{
                  scope.$index +
                    1 +
                    (searchForm.pageIndex - 1) * searchForm.pageSize
                }}
              </span>
            </template>
          </el-table-column>
          <!-- 序号 end -->
          <el-table-column
            show-overflow-tooltip
            v-for="(column, index) in columnData"
            :key="index"
            :prop="column.fieldName"
            :label="column.fieldLabel"
            :min-width="column.width"
            :fixed="column.fixed"
            :align="column.align"
            header-align="center"
          >
            <template slot-scope="{ row }">
              <span v-if="column.fieldName === 'chargeCode'">
                <el-button type="text" @click="handleGoToBillDetail(row)">
                  {{ row[column.fieldName] }}
                </el-button>
              </span>
              <span
                v-else-if="column.fieldName === 'taskStatusName'"
                :class="[
                  'status',
                  row.taskStatus == 3 || row.taskStatus == 4
                    ? 'finish'
                    : row.taskStatus === 2
                    ? 'ongoing'
                    : ''
                ]"
              >
                {{ row[column.fieldName] }}
              </span>
              <span v-else>{{ row[column.fieldName] }}</span>
            </template>
          </el-table-column>
          <el-table-column
            fixed="right"
            align="center"
            label="操作"
            width="120"
          >
            <template slot-scope="scope">
              <e6-td-operate
                :data="getOperateList()"
                @command="handleOperate($event, scope.row)"
              ></e6-td-operate>
            </template>
          </el-table-column>
        </el-table>
      </section>
      <!-- 表格 end -->

      <!-- 分页 start -->
      <section class="pagination-wrapper fixed-section">
        <el-pagination
          :page-size.sync="searchForm.pageSize"
          :current-page.sync="searchForm.pageIndex"
          :page-sizes="pageSizes"
          :layout="layout"
          :total="total"
          background
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
        ></el-pagination>
      </section>
      <!-- 分页 end -->
    </div>
    <!-- 创建工单弹框 -->
    <add-task-dialog
      :showAdd="showAdd"
      @closeDialog="closeDialog"
    ></add-task-dialog>
  </div>
</template>

<script>
import base from "@/mixins/base";
import listPage from "@/mixins/list-page";
import listPageReszie from "@/mixins/list-page-resize";
import { teamTaskList, findDownList } from "@/api";
import { printError } from "@/utils/util";
import addTaskDialog from "@/components/workOrderManage/addTaskDialog";
import allUserSearch from "@/components/allUserSearch";
export default {
  name: "teamWorkList",
  data() {
    return {
      searchForm: {
        taskNumber: "", //协同单号
        taskStatus: "", //协同单状态
        title: "", //协同单标题
        operaEmployeeId: "", //当前操作人
        createByName: "", //创建人
        createdTimeEnd: "", //创建时间结束
        createdTimeStart: "", //创建时间开始
        createTime: [],
        pageIndex: 1,
        pageSize: 20
      },
      workStatusList: [], //工单状态
      selectLoading: false,
      total: 0,
      columnData: [
        {
          fieldName: "taskNumber",
          display: true,
          fieldLabel: "协同单号",
          width: 160,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "title",
          display: true,
          fieldLabel: "协同单标题",
          width: 160,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "taskStatusName",
          display: true,
          fieldLabel: "协同单状态",
          width: 160,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "operaEmployeeName",
          display: true,
          fieldLabel: "当前操作人",
          width: 160,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "operaStatusName",
          display: true,
          fieldLabel: "当前操作状态",
          width: 160,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "handlePrescriptionStr",
          display: true,
          fieldLabel: "处理时效",
          width: 160,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "createBy",
          display: true,
          fieldLabel: "创建人",
          width: 160,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "createdTime",
          display: true,
          fieldLabel: "创建时间",
          width: 160,
          disabled: false,
          fixed: false,
          align: "center"
        }
      ], //表头
      tableData: [], // 表格数据
      showAdd: false, //创建工单弹屏是否显示
      refTable: "elTable",
      queryListAPI: teamTaskList
    };
  },
  mixins: [listPage, base, listPageReszie],
  watch: {
    // 创建开始时间
    "searchForm.createTime": {
      immediate: true,
      handler(val) {
        if (val[0] && val[1] && val[0] > val[1]) {
          this.searchForm.createTime = this.searchForm.createTime.reverse();
        }
        this.searchForm.createdTimeStart = val[0] || "";
        this.searchForm.createdTimeEnd = val[1] || "";
      }
    }
  },
  components: {
    addTaskDialog,
    allUserSearch
  },
  created() {
    this.initData();
    this.queryList();
  },
  mounted() {
    this.setTableScrollDOM("elTable");
  },
  activated() {
    this.setScrollRecord();
  },
  methods: {
    // handleSearch1() {
    //   window.open("", "_self").close();
    // },
    async initData() {
      try {
        this.selectLoading = true;
        let promiseList = [findDownList(["workStatus"])];
        let [workStatusRes] = await Promise.all(promiseList);
        //工单状态
        this.workStatusList = this.getFreezeResponse(workStatusRes, {
          path: "data.workStatus"
        });
      } catch (error) {
        printError(error);
      } finally {
        this.selectLoading = false;
      }
    },
    addTask() {
      this.routerPush({
        name: "teamWork/teamWorkAdd",
        params: {
          refresh: true
        }
      });
    },

    // 获取页面操作权限
    getOperateList() {
      // 页面按钮权限
      let operates = [
        {
          label: "详情",
          id: 1
        }
      ];
      return operates;
      // return this.filterOperateBtn(operates);
    },
    // 操作--更多
    handleOperate(val, row) {
      if (val.id == 1) {
        this.routerPush({
          name: "teamWork/teamWorkDetail",
          params: {
            refresh: true,
            synergyId: row.synergyId
          }
        });
      }
    },
    //创建工单
    createTask() {
      this.showAdd = true;
    },
    //关闭弹框
    closeDialog() {
      this.showAdd = false;
    }
  }
};
</script>
<style lang="scss" scoped></style>
